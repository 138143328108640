import React from 'react';
import { connect } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Button from '@material-ui/core/Button';
import FptForceAnmeldung from '../utils/FptForceAnmeldung';
import FptHeader from '../utils/FptHeader';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import FptLink from '../utils/FptLink';
import PeopleIcon from '@material-ui/icons/People';
import BusinessIcon from '@material-ui/icons/Business';
import PressegesprächIcon from '@material-ui/icons/RecordVoiceOver';
import ScheduleIcon from '@material-ui/icons/Schedule';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';

import { config } from '../../config/config';
import onlinePkImage from './fpt-smartphone.png';
import onlineVotingImage from './online_voting.png';
import { Card, CardContent, Divider, makeStyles } from '@material-ui/core';
import { startTeilnahmeRedakteurAnmelden } from '../../actions/fpt';
import FormAnmelden from './generic/FormAnmelden';
import { checkAnmeldungJ } from '../../helper/fptHelper';




// Aktuelle Veranstaltung und Titel aus der Config auslesen
const idFpt = config.currentFpt;
const titel = config[config.currentFpt].titel; // 'Fachpressetage 2025';

const useStyles = makeStyles((theme) => ({
	introLinks: {
		[theme.breakpoints.up('md')]: {
			width: '80%',
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)'
		},
		[theme.breakpoints.up('lg')]: {
			width: '70%',
		},
		width: '95%',
	},
	introRechts: {
		width: '100%',
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)'
	}
}));

const PageStart = (props) => {

	const history = useHistory();
	const classes = useStyles();

	const openPage = (target) => {
		history.push(target);
	}

	const isJournalist = props.user && props.user.idFptTeilnehmerTyp.includes('j');

	const bttnResetAnmeldung = () => {
		props.dispatch(startTeilnahmeRedakteurAnmelden(props.user, idFpt, 'j'));
	}

	const getAnmeldeungsTypRedakteur = (user) => {
		const redakteur = props.teilnehmerRedakteur && props.teilnehmerRedakteur.find(r => r.idFptProfilPerson == user.idFptProfilPerson && r.idAuftragFpt.includes(idFpt));
		return redakteur ? redakteur.idFptTeilnehmerTyp && redakteur.idFptTeilnehmerTyp.trim() : undefined;
	}

	const FptLink = (props) => {
		return (
			props.disabled ?
				<Typography variant="body2" style={{ color: '#aaa' }}>
					{props.children} (Link folgt)
				</Typography>
				:
				<Link to={'/' + props.idFpt + props.to}>
					{props.children}
				</Link>
		)
	}


	return (
		<React.Fragment>
			<FptHeader title={titel} />


			<FptForceAnmeldung bypass={config[idFpt].votingOpen} idFpt={idFpt} fptTitel={titel}>

				<Grid container spacing={2} style={{ height: '100%' }}>
					<Grid item xs={12} md={12} lg={5} xl={4} style={{ position: 'relative', }}>
						<div >
							{(isJournalist && config[idFpt].votingOpen) ?

								<React.Fragment>
									<img src={onlineVotingImage} style={{ maxWidth: '400px' }} />
									<Typography variant="h3" >
										Wahl
									</Typography>
									<Typography variant="h3" gutterBottom style={{ color: '#ff7e00' }}>
										Vortragsprogramm
									</Typography>
									<Typography variant="body1" gutterBottom>
										Auch wenn Sie sich noch nicht anmelden können, dürfen Sie jetzt mitentscheiden, welche
										Themen in das Programm aufgenommen werden sollen. Wir freuen uns, wenn Sie dieses Wahlrecht nutzen!
									</Typography>
									<Button
										style={{ margin: '8px' }}
										variant="outlined"
										color="secondary"
										onClick={() => openPage(`/${idFpt}/bewertung`)}
									>
										Hier finden Sie Ihren "Wahlschein"
									</Button>
								</React.Fragment>

								:

								<React.Fragment>
									<Typography variant="h3" >
										Web-App
									</Typography>
									<Typography variant="h3" gutterBottom style={{ color: '#ff7e00' }}>
										Fachpressetage
									</Typography>

									<Button
										style={{ margin: '8px' }}
										variant="contained"
										color="secondary"
										onClick={() => openPage(`/${idFpt}/themen`)}
										startIcon={<BusinessIcon />}
									>
										Alle Firmen und Themen
									</Button><br />
									<Button
										style={{ margin: '8px' }}
										variant="contained"
										color="secondary"
										onClick={() => openPage(`/${idFpt}/teilnehmer`)}
										startIcon={<PeopleIcon />}
									>
										Teilnehmer:innen
									</Button><br />
									<Button
										style={{ margin: '8px' }}
										variant="contained"
										color="secondary"
										onClick={() => openPage(`/${idFpt}/pressegespraech`)}
										startIcon={<PressegesprächIcon />}
									>
										Pressegesprächs-Tische
									</Button><br />
									<Button
										style={{ margin: '8px' }}
										variant="contained"
										color="secondary"
										onClick={() => openPage(`/${idFpt}/programm`)}
										startIcon={<ScheduleIcon />}
									>
										Programm
									</Button><br />
									<Button
										style={{ margin: '8px' }}
										variant="outlined"
										color="secondary"
										startIcon={<LiveHelpIcon />}
										onClick={() => window.open("https://fachpressetage.rbsonline.de/FAQ", "popup", "directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,height=700,width=1100,left=10,top=10")}
									>
										FAQ
									</Button>

									{config[idFpt].live &&
										<Card color='secondary' variant="outlined" style={{ margin: '8px', backgroundColor: '#ff7e00', color: '#fff' }}>
											<CardContent>
												<Typography variant="h5" gutterBottom>
													Live-Stream
												</Typography>
												<Typography variant="body2" >
													Dienstag 06.02.: ab 9:00 Uhr<br />
													Mittwoch 07.02.: ab 9:00 Uhr<br /><br />
												</Typography>
												<Button
													variant="contained"
													color="secondary"
													onClick={() => history.push(`/${idFpt}/live`)}
												>
													Zum Livestream
												</Button>
											</CardContent>
										</Card>
									}

									{!isJournalist && 1 == 2 &&
										<Alert severity="warning" style={{ margin: '8px' }}>
											<AlertTitle>Thema einreichen bis zum 22. Oktober 2022</AlertTitle>
											Falls Sie es noch nicht getan haben, denken Sie bitte daran, Ihr Thema bis zum 22.10.
											<br />
											<Button
												onClick={() => openPage(`/${idFpt}/meineThemen`)}
												style={{ margin: '8px 0' }}
												variant="contained"
												color="secondary"
											>
												hier einzutragen
											</Button>

										</Alert>
									}

									{getAnmeldeungsTypRedakteur(props.user) == 'jc' &&
										<Alert style={{ margin: '8px' }} severity="error">
											Sie sind für die Veranstaltung {titel} nur als online-User abgemeldet.<br /><br />
											Meinung geändert? Sie möchten zur Präsenzveranstaltung kommen? Schreiben Sie uns bitte eine &nbsp;
											<a href="mailto:kontakt@rbsonline.de">Mail</a>
										</Alert>
									}
								</React.Fragment>
							}
						</div>



					</Grid>

					<Grid item xs={12} md={12} lg={6} style={{ border: 'none', }}>

						{(isJournalist && config[idFpt].votingOpen) ?
							<React.Fragment>
								{config[idFpt].anmeldungJ && !checkAnmeldungJ(idFpt, props.user, props.teilnehmerRedakteur) && <FormAnmelden fptTitel={titel} idFpt={idFpt} />}
							</React.Fragment>
							:
							<img src={onlinePkImage} style={{ maxWidth: '70%', border: 'none' }} />
						}

						{/* vor img div className={classes.introRechts} rausgenommen*/}
					</Grid>

				</Grid>
			</FptForceAnmeldung>


		</React.Fragment>
	);

}


const mapStateToProps = state => ({
	user: state.global.user,
	teilnehmerRedakteur: state.global.teilnehmerRedakteur,
	teilnehmerFirma: state.global.teilnehmerFirma,
});

export default connect(mapStateToProps)(PageStart);