import React, { useEffect, useState } from "react";
import { Collapse, List, ListItem, ListItemText } from "@material-ui/core";
import { config } from '../../config/config';
import SubNavListItemMain from "./SubNavListItemMain";
import SubNavItemIcon from "./SubNavItemIcon";

// Icons
import LiveTvIcon from '@material-ui/icons/LiveTv';
import GroupIcon from '@material-ui/icons/Group';
import ChatIcon from '@material-ui/icons/Chat';
import BusinessIcon from '@material-ui/icons/Business';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDown';
import EventNoteIcon from '@material-ui/icons/EventNote';
import IconWerWann from '@material-ui/icons/PlaylistAddCheck'
import GespraechIcon from '@material-ui/icons/RecordVoiceOver';
import QuestionMarkIcon from '@material-ui/icons/HelpOutline';


const iconColor = '#fff';
const idFpt = 'fpt036';

const SubNavigation2025 = (props) => {

    const [menuOpen, setMenuOpen] = useState(props.defaultOpen);

    useEffect(() => {
        props.user && console.log("user", props.user.idFptTeilnehmerTyp, config[idFpt]);
    }, [props.user]);

    const openPage = (page) => {
        props.onOpenPage && props.onOpenPage(page);
    }

    return (
        <React.Fragment>
            <SubNavListItemMain
                onClick={() => setMenuOpen(!menuOpen)}
                primary="Fachpressetage 2025"
                secondary="04. &amp; 05. Februar"
                open={menuOpen}
            />
            <Collapse in={menuOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>

                    <ListItem style={{ backgroundColor: !!config.fpt036.live ? '#f37800' : '#111' }} button disabled={!config.fpt036.live} onClick={() => openPage('/fpt036/live')}>
                        <SubNavItemIcon>
                            <LiveTvIcon style={{ color: iconColor }} />
                        </SubNavItemIcon>
                        <ListItemText primary="Live-Stream" />
                    </ListItem>

                    {props.user && props.user.idFptTeilnehmerTyp.includes('j') && config[idFpt].votingOpen &&
                        <ListItem button style={{ backgroundColor: '#f37800' }} onClick={() => openPage('/fpt036/bewertung')}>
                            <SubNavItemIcon><ThumbsUpDownIcon style={{ color: iconColor }} /></SubNavItemIcon>
                            <ListItemText primary="Vorträge bewerten" />
                        </ListItem>
                    }

                    <ListItem button onClick={() => openPage('/fpt036/themen')}>
                        <SubNavItemIcon><BusinessIcon style={{ color: iconColor }} /></SubNavItemIcon>
                        <ListItemText primary="Firmen &amp; Themen" />
                    </ListItem>
                    <ListItem button onClick={() => openPage('/fpt036/teilnehmer')}>
                        <SubNavItemIcon><GroupIcon style={{ color: iconColor }} /></SubNavItemIcon>
                        <ListItemText primary="Teilnehmer:innen" />
                    </ListItem>
                    <ListItem button onClick={() => openPage('/fpt036/pressegespraech')}>
                        <SubNavItemIcon><GespraechIcon style={{ color: iconColor }} /></SubNavItemIcon>
                        <ListItemText primary="Pressegesprächs-Tische" />
                    </ListItem>
                    <ListItem button onClick={() => openPage('/fpt036/programm')}>
                        <SubNavItemIcon><EventNoteIcon style={{ color: iconColor }} /></SubNavItemIcon>
                        <ListItemText primary="Programm" />
                    </ListItem>

                    <ListItem button onClick={() => openPage('/fpt036/redaktionen')}>
                        <SubNavItemIcon><ImportContactsIcon style={{ color: iconColor }} /></SubNavItemIcon>
                        <ListItemText primary="Redaktionen" />
                    </ListItem>

                    {1 == 2 &&
                        <ListItem button onClick={() => openPage('/fpt036/werWann')}>
                            <SubNavItemIcon><IconWerWann style={{ color: iconColor }} /></SubNavItemIcon>
                            <ListItemText primary="Wer kommt wann?" />
                        </ListItem>
                    }
                    <ListItem button onClick={() => window.open("https://fachpressetage.rbsonline.de/FAQ", "popup", "directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,height=700,width=1100,left=10,top=10")}>
                        <SubNavItemIcon><QuestionMarkIcon style={{ color: iconColor }} /></SubNavItemIcon>
                        <ListItemText primary="FAQ" />
                    </ListItem>
                    {props.user && props.user.idFptTeilnehmerTyp.includes('f') &&
                        <ListItem button onClick={() => openPage('/fpt036/meineThemen')}>
                            <SubNavItemIcon><ChatIcon style={{ color: iconColor }} /></SubNavItemIcon>
                            <ListItemText primary="Meine Themen" />
                        </ListItem>
                    }
                </List>
            </Collapse>
        </React.Fragment>

    )
}

export default SubNavigation2025;